<!-- list -->
<template>
  <el-main>
    <page-title />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card" @tab-click="tabClickRoleList()">
        <el-tab-pane label="平台端" name="tenant">
          <ics-role-list-inner :loading="loading.list" :table-list="tableList" :table-key="tableKey" :table-active-name="activeName" :process-list="processList" view-mode @search="tabClickRoleList" />
        </el-tab-pane>
        <el-tab-pane label="核心企业端" name="core">
          <ics-role-list-inner :loading="loading.list" :table-list="tableList" :table-key="tableKey" :table-active-name="activeName" :process-list="processList" view-mode @search="tabClickRoleList" />
        </el-tab-pane>
        <el-tab-pane label="融资端" name="customer">
          <ics-role-list-inner :loading="loading.list" :table-list="tableList" :table-key="tableKey" :table-active-name="activeName" :process-list="processList" view-mode @search="tabClickRoleList" />
        </el-tab-pane>
        <el-tab-pane label="资方端" name="capital">
          <ics-role-list-inner :loading="loading.list" :table-list="tableList" :table-key="tableKey" :table-active-name="activeName" :process-list="processList" view-mode @search="tabClickRoleList" />
        </el-tab-pane>
        <el-tab-pane label="运营端" name="channel">
          <ics-role-list-inner :loading="loading.list" :table-list="tableList" :table-key="tableKey" :table-active-name="activeName" :process-list="processList" view-mode @search="tabClickRoleList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>
<script>
import IcsRoleListInner from './components/role-list-inner'
export default {
  components: { IcsRoleListInner },
  data () {
    return {
      activeName: this.$route.query.activeName ? this.$route.query.activeName : 'tenant',
      processList: [],
      tableKey: {
        appKey: ''
      },
      tableList: {
        list: []
      },
      loading: {
        list: false
      }
    }
  },
  computed: {
  },
  watch: {},
  created () {
    // this.findAuth()
    this.tabClickRoleList()
  },
  methods: {
    tabClickRoleList (searchForm) {
      console.log(searchForm)
      if (!this.activeName) this.activeName = 'tenant'
      let searchData = searchForm
      let appKey = ''
      if (this.activeName === 'tenant') {
        appKey = 'finance-frame-tenant'
      } else if (this.activeName === 'customer') {
        appKey = 'finance-frame-customer'
      } else if (this.activeName === 'capital') {
        appKey = 'finance-frame-capital'
      }else if (this.activeName === 'core') {
        appKey = 'finance-frame-core'
      }else if (this.activeName === 'channel') {
        appKey = 'finance-frame-channel'
      }
      this.tableKey.appKey = appKey
      if (searchData) {
        searchData.pageSize = 10
        searchData.appKey = appKey
      } else {
        searchData = {
          pageSize: 10,
          appKey: appKey
        }
      }
      this.loading.list = true
      this.api.system.role.rolePage(searchData).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>
<style></style>
